<template>
  <div>
    <navBar>
      <div class="pages">
        <div class="mt40 title">
          {{ detail.title }}
        </div>
        <div class="time mt10">
          {{ detail.createdTime }}
        </div>
        <div class="container mt20" v-html="detail.content"></div>
      </div>
    </navBar>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import navBar from '@/components/navBar/index.vue'
export default {
  components: {
    navBar
  },
  data() {
    return {
      detail: '',
      loading: ''
    }
  },
  created() {
    this.loading = Loading.service(true)
    this.$route.params.id && this.getDetail()
  },
  methods: {
    getDetail() {
      this.$axios
        .post('/notification/detail/' + this.$route.params.id)
        .then((res) => {
          this.detail = res.data.result
          this.loading.close()
          console.log('detail', this.detail)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.pages {
  width: 1200px;
  margin: auto;
}
.title {
  color: #000;
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
}
.time {
  color: #979797;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.container {
  ::v-deep img {
    width: 100%;
  }
}
</style>
